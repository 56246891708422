<template>
  <div style="display: contents">
    <div id="mainHeader" :class="headerClasses">
      <div class="container">
        <div ref="headerInner" class="hb-main-header__inner">
          <div class="hb-main-header__left">
            <hb-logo type="main" @click-on-logo="isOpen = false" />
          </div>
          <div class="hb-main-header__right">
            <main-menu v-if="!asSearchHeader" />
            <div v-else class="hb-main-header__search">
              <location-search :is-small="true" top-level />
            </div>
            <!--            <div class="hb-main-header__separator"></div>-->
            <div class="hb-main-header__actions">
              <div class="hb-main-header__search-icon-mobile">
                <location-search
                  :is-small="true"
                  :is-icon-only="true"
                  class=""
                />
                <nuxt-icon
                  name="search"
                  filled
                  class="hb-main-header__search-icon-mobile__placeholder"
                ></nuxt-icon>
              </div>
              <!-- <notification-btn /> -->
              <profile-btn />
              <hamburger
                :class="{ 'hamburger--open': isOpen }"
                @click="openMenu()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-menu" :class="{ 'mobile-menu--open': isOpen }">
      <main-menu v-if="true" />
      <div class="mobile-menu__socials">
        <a :href="socialUrls.facebook" target="_blank" aria-label="facebook">
          <nuxt-icon name="facebook" filled></nuxt-icon>
        </a>
        <a :href="socialUrls.linkedin" target="_blank" aria-label="linkedin">
          <nuxt-icon name="linkedin" filled></nuxt-icon>
        </a>
        <a :href="socialUrls.instagram" target="_blank" aria-label="instagram">
          <nuxt-icon name="instagram" filled></nuxt-icon>
        </a>
        <a :href="socialUrls.twitter" target="_blank" aria-label="twitter">
          <nuxt-icon name="twitter-x" filled></nuxt-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useElementSize, useWindowScroll, useWindowSize } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { disableDocumentScroll, enableDocumentScroll } from 'wue'
import MainMenu from '~/components/MainMenu.vue'
import LocationSearch from '~/components/form/LocationSearch.vue'
import Hamburger from '~/components/Hamburger.vue'
import ProfileBtn from '~/modules/auth/components/ProfileBtn.vue'
import HbLogo from '~/components/base/HbLogo.vue'
import {
  chatToBackground,
  chatToDefault,
  removePaddingTopFromDocElement,
  setPaddingTopOnDocElement,
} from '~/utils'
import { SOCIAL_PAGE_URLS } from '~/constants'

export default {
  name: 'MainHeader',
  components: {
    HbLogo,
    LocationSearch,
    MainMenu,
    Hamburger,
    ProfileBtn,
  },
  props: {
    asSearchHeader: { type: Boolean, default: false },
    fixedOpenMainHeader: { type: Boolean, default: true },
  },
  setup(props) {
    const isOpen = ref(false)
    const isScrolled = ref(false)
    const headerInner = ref<HTMLElement>(null)
    const socialUrls = ref(SOCIAL_PAGE_URLS)
    const { y: windowScrollY } = useWindowScroll()
    const { width: windowWidth } = useWindowSize()
    const { width: headerInnerWidth } = useElementSize(headerInner)
    const underlineFillSize = computed(() => {
      const headerWidth = headerInnerWidth.value
      let size = windowScrollY.value / 2

      if (size + headerWidth >= windowWidth.value) {
        size = windowWidth.value - headerWidth
      }

      return size * 4 + 'px'
    })
    const showLocationSearch = computed(() => windowScrollY.value > 50)
    const headerHasShadow = computed(() => windowScrollY.value > 300)
    const headerClasses = computed(() => ({
      'hb-main-header': true,
      'hb-main-header--open': isOpen.value,
      'hb-main-header--open--fixed': isOpen.value && props.fixedOpenMainHeader,
      'hb-main-header--scrolled': isScrolled.value,
      'hb-main-header--search-header': props.asSearchHeader,
      'hb-main-header--shadowed': headerHasShadow.value,
    }))

    const openMenu = () => (isOpen.value = !isOpen.value)

    watch(
      () => isOpen.value,
      () => {
        if (isOpen.value) {
          disableDocumentScroll()
          chatToBackground()

          if (props.fixedOpenMainHeader) {
            setPaddingTopOnDocElement()
          }
        } else {
          enableDocumentScroll()
          chatToDefault()

          if (props.fixedOpenMainHeader) {
            removePaddingTopFromDocElement()
          }
        }
      }
    )

    const route = useRoute()
    watch(
      route,
      (to) => {
        isOpen.value = false
      },
      { flush: 'pre', immediate: true, deep: true }
    )

    return {
      headerClasses,
      headerInner,
      underlineFillSize,
      showLocationSearch,
      headerHasShadow,
      socialUrls,
      openMenu,
      isOpen,
      isScrolled,
    }
  },
}
</script>

<style lang="scss">
:root {
  --hb-main-header-height: 80px;
}

.hb-main-header {
  --hb-main-header-underline-width: 100%;
  --hb-main-header-underline-fill-size: v-bind(underlineFillSize);
  position: sticky;
  top: 0;
  background: var(--hb-white);
  z-index: 5;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 5px 20px rgba(#aab8be, 0);

  &--shadowed {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

    @include tablet {
      box-shadow: none;
    }
  }

  &--open {
    width: 100%;
    right: 0;
    left: 0;

    &--fixed {
      position: fixed;
    }
  }

  &__search {
    position: relative;
    width: 100%;
    max-width: 550px;
    margin-right: 40px;
  }

  &__search-icon-mobile {
    width: 23px;
    height: 30px;
    font-size: 23px;
    margin-top: 7px;
    position: relative;
    display: none !important;

    &__placeholder {
      position: absolute;
      top: 0;
      pointer-events: none;
    }
  }

  &__inner {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: var(--hb-main-header-height);
    gap: 50px;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: 0;
      max-width: 100vw;
      width: calc(
        var(--hb-main-header-underline-width) +
          var(--hb-main-header-underline-fill-size)
      );
      min-width: 100%;
      height: 6px;
      border-radius: 100px;
      background: linear-gradient(
        90deg,
        var(--hb-primary),
        var(--hb-secondary)
      );
      transform: translate(-50%, 50%);
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }

    &:hover {
      &:after {
        animation-play-state: paused;
      }
    }
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    justify-content: flex-end;
    flex: 1;
  }

  &__separator {
    width: 1px;
    height: 100%;
    background: var(--hb-gray2);
    margin: 0 42px 0 25px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 35px;
  }

  .hb-main-menu {
    margin-left: auto;
    margin-right: 30px;
    display: inline-flex;
  }

  @include tablet {
    z-index: 11;

    &__inner {
      --hb-main-header-height: 80px;
    }

    &__actions {
      gap: 15px;
    }

    &__separator {
      display: none;
    }

    .hb-main-menu {
      display: none;
    }

    &__search-icon-mobile {
      display: block !important;
    }

    &__search {
      display: none;
    }
  }

  &--search-header {
    .container {
      max-width: unset;
      padding: 0 30px;
    }

    &:not(.hb-main-header--open) {
      .hb-main-header__inner {
        &:after {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}

.mobile-menu {
  display: none;

  @include tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 80px);
    padding: 13.2vh 0 50px;
    background-color: #fff;
    z-index: 10;
    transform: translateX(100%);
    visibility: hidden;
    transition: 0.3s all;

    &--open {
      visibility: visible;
      transform: translateX(0);

      .mobile-menu__socials {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &__socials {
      display: flex;
      gap: 32px;
      opacity: 0;
      transform: translateY(20px);
      transition: 0.3s all;
      transition-delay: 0.15s;

      a {
        color: var(--hb-blue1);
      }

      .nuxt-icon {
        font-size: 30px;
      }
    }
  }
}
</style>
