<template>
  <ul class="hb-main-menu">
    <li v-for="(item, index) in items" :key="index">
      <!-- <nuxt-link :to="item.path">{{ $t(item.labelKey) }}</nuxt-link> -->
      <nuxt-link
        class="no-route"
        v-if="checkRoute(item)"
        @click="openInProgressDialog"
      >
        {{ $t(item.labelKey) }}
      </nuxt-link>
      <nuxt-link v-else :to="item.path">{{ $t(item.labelKey) }}</nuxt-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { ref } from "vue";
import { Routes } from "~/enums";
import { useDM } from "~/composables";
import { useRuntimeConfig } from "#app";
import ChangesComingDialog from "~/modules/auth/components/dialogs/ChangesComingDialog.vue";

interface MenuItem {
  labelKey: string;
  path: string;
}

export default {
  name: "MainMenu",
  setup() {
    const config = useRuntimeConfig();
    const appUrl = config.public.baseUrl;

    const menuItems: MenuItem[] = [
      { labelKey: "menu.search", path: appUrl + Routes.Search },
      // { labelKey: "menu.sell", path: appUrl + Routes.HomeUpload },
      { labelKey: "menu.about", path: appUrl + Routes.About },
      // { labelKey: "menu.for_developers", path: appUrl + Routes.ForDevelopers },
      { labelKey: "menu.why_homebourse", path: appUrl + Routes.Buy },
      { labelKey: "menu.blog", path: appUrl + Routes.Blog },
      // { labelKey: 'menu.help', path: Routes.Help },
    ];
    const items = ref<MenuItem[]>(menuItems);

    const openInProgressDialog = () => {
      useDM().open(ChangesComingDialog, {
        closeByEsc: true,
        closeByOverlay: true,
      });
    };

    const checkRoute = (item) => {
      if (item.path === Routes.Search || item.path === Routes.HomeUpload)
        return true;
      return false;
    };

    return {
      items,
      openInProgressDialog,
      checkRoute,
    };
  },
};
</script>

<style lang="scss">
.no-route:hover {
  cursor: pointer;
}

.hb-main-menu {
  display: flex;
  align-items: center;
  height: 100%;

  li {
    list-style: none;
    height: 100%;

    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      color: var(--hb-blue1);
      text-decoration: none;
      padding: 0 14px;
      height: 100%;
      position: relative;
      transition: color 0.3s;

      &:hover {
        color: #98a5ab;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        height: 6px;
        background-color: transparent;
        z-index: 1;
        transition: 0.3s all;
      }

      &.router-link-active {
        &:before {
          background-color: var(--hb-primary);
        }
      }

      @include mobile {
        &.router-link-active {
          color: #98a5ab;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  @include tablet {
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: auto;

    li {
      a {
        font-size: 26px;
        padding: 0;
      }
    }
  }
}
</style>
