<template>
  <div class="hb-logo" :class="{ [`hb-logo--${type}`]: type }">
    <nuxt-link :to="appUrl" class="hb-logo__link" @click="emitClickOnLogo()">
      <nuxt-icon name="logo" filled />
      <span class="hb-logo__text">Homebourse</span>
    </nuxt-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRuntimeConfig } from '#app'

export default defineComponent({
  name: 'HbLogo',
  components: {},
  props: {
    type: { type: String, default: null },
  },
  emits: ['click-on-logo'],
  setup(props, ctx) {
    const config = useRuntimeConfig()
    const appUrl = config.public.baseUrl

    const emitClickOnLogo = () => ctx.emit('click-on-logo')

    return {
      appUrl,
      emitClickOnLogo,
    }
  },
})
</script>

<style lang="scss">
.hb-logo {
  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .nuxt-icon {
    font-size: 36px;
    margin-right: 20px;
  }

  &__text {
    font-weight: 700;
    color: var(--hb-secondary);
    font-size: 25px;
    letter-spacing: -0.5px;
  }

  &--main {
    @include tablet {
      .hb-logo__text {
        display: none;
      }
    }
  }

  &--small {
    .nuxt-icon {
      font-size: 30px;
      margin-right: 18px;
    }

    .hb-logo__text {
      font-size: 22px;
    }
  }
}
</style>
