<template>
  <div class="profile-wrapper">
    <hb-btn
      v-bind="$props"
      ref="dropdownTriggerEl"
      theme="icon"
      class="hb-btn-icon--bordered profile-button"
      :class="{ 'profile-button--logged-in': isLoggedIn }"
      aria-label="login"
      @click="clickOnProfileBtn()"
    >
      <name-initials-image
        v-if="!storedUser?.images"
        class="profile-button__image"
      />
      <hb-image
        v-else
        class="profile-button__image"
        :image="storedUser.images"
      />
    </hb-btn>
    <client-only>
      <bottom-sheet v-model="isOpen">
        <profile-dropdown v-model="isOpen"></profile-dropdown>
      </bottom-sheet>
    </client-only>
  </div>
</template>

<script lang="ts">
import { BtnMixin } from 'wue'
import { onClickOutside } from '@vueuse/core'
import type { Ref } from 'vue'
import { computed, ref, watch } from 'vue'
import type { User } from '@homebourse/api-client'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import AuthDialog from '~/modules/auth/components/dialogs/AuthDialog.vue'
import { useApiHandler, useDM } from '~/composables'
import { useViewTools } from '#imports'
import { useAuthStore, useUserStore } from '~/stores'
import NameInitialsImage from '~/modules/auth/components/NameInitialsImage.vue'
import HbImage from '~/components/base/HbImage.vue'
import BottomSheet from '~/components/BottomSheet.vue'
import ProfileDropdown from '~/components/ProfileDropdown.vue'

export default {
  name: 'ProfileBtn',
  components: {
    HbImage,
    NameInitialsImage,
    HbBtn,
    BottomSheet,
    ProfileDropdown,
  },
  mixins: [BtnMixin],
  emits: ['opened', 'closed'],
  setup(props, ctx) {
    const userStore = useUserStore()
    const storedUser = computed(() => userStore.user)
    const isLoggedIn = computed(() => !!storedUser.value)
    const isOpen = ref(false)
    const dropdownTriggerEl = ref<HTMLElement>(null)
    const dropdownEl = ref<HTMLElement>(null)
    const toggleProfileDropdown = () => (isOpen.value = !isOpen.value)
    const clickOnProfileBtn = () => {
      if (!isLoggedIn.value) {
        useDM().open(AuthDialog)
      } else {
        toggleProfileDropdown()
      }
    }
    const { execute: signOut, pending: signOutPending } = useApiHandler(
      async () => {
        const response = await useAuthStore().signOut()
        toggleProfileDropdown()
        return response
      }
    )

    onClickOutside(
      dropdownEl,
      () => {
        isOpen.value = false
      },
      { ignore: [dropdownTriggerEl] }
    )

    watch(
      () => isOpen.value,
      (value) => ctx.emit(value ? 'opened' : 'closed')
    )

    return {
      storedUser: storedUser as Ref<User>,
      isLoggedIn,
      isOpen,
      dropdownTriggerEl,
      dropdownEl,
      signOut,
      signOutPending,
      clickOnProfileBtn,
      ...useViewTools(),
    }
  },
}
</script>

<style lang="scss">
.profile-button {
  padding: 15px !important;
  transition: 0.3s all;

  &--logged-in {
    padding: 6px !important;

    &:hover {
      box-shadow: inset 0 0 0 2px var(--hb-gray2);
    }
  }

  &__image {
    display: block;
    width: 38px;
    height: 38px;
    object-fit: cover;
    object-position: top center;
    border-radius: 7px;
  }

  @include tablet {
    &:not(&--logged-in) {
      border: 0;
      padding: 5px;

      .nuxt-icon {
        font-size: 22px;
      }
    }
  }
}

.profile-wrapper {
  position: relative;
}

.profile-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: calc(100% - 52px);
  z-index: 10;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 20px 99px #aab8be80;
  width: 240px;
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px) scale(0);
  transform-origin: top right;
  will-change: transform, opacity;

  @include mobile {
    position: static;
    opacity: 1;
    visibility: visible;
    transform: translateY(0) scale(1);
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  &--open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) scale(1);
  }

  &__top {
    padding: 27px 20px 17px 20px;
    border-bottom: 1px solid var(--hb-gray2);
  }

  &__link-friends {
    padding: 13px 20px 13px 20px;
    border-bottom: 1px solid var(--hb-gray2);

    @include mobile {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &__bottom {
    padding: 17px 20px 22px 20px;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: inherit;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    text-decoration: none;
    transition: 0.3s all;

    .nuxt-icon {
      color: var(--hb-primary);
      margin-right: 14px;
      font-size: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--total {
      justify-content: space-between;
    }

    &:hover {
      color: #98a5ab;
    }

    &--disabled {
      pointer-events: none;
      user-select: none;
      user-focus: none;
      touch-action: none;
      opacity: 0.6;
      filter: grayscale(1);
    }

    &__spinner {
      line-height: 0;
    }

    @include mobile {
      font-size: 16px;
      margin-bottom: 26px;
    }
  }

  &__total {
    font-weight: 600;
  }

  &__link-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    font-size: 10px;
    font-weight: 600;
    border: 1px solid var(--hb-gray4);
    border-radius: 50%;
    margin-left: 8px;
  }
}
</style>
