<template>
  <div
    v-if="isLoggedIn"
    ref="dropdownEl"
    class="profile-dropdown"
    :class="{ 'profile-dropdown--open': model }"
  >
    <div class="profile-dropdown__top">
      <nuxt-link
        :to="appUrl + '/profile/saved-searches'"
        class="profile-dropdown__link"
      >
        {{ $t("profile.saved_searches") }}
        <span class="profile-dropdown__link-number">
          {{ storedUser.saved_search_count || 0 }}
        </span>
      </nuxt-link>
      <!-- <nuxt-link :to="appUrl + '/saved-homes'" class="profile-dropdown__link">
        {{ $t('profile.saved_homes') }}
        <span class="profile-dropdown__link-number">
          {{ storedUser.saved_properties_count || 0 }}
        </span>
      </nuxt-link> -->
      <!-- <nuxt-link
        :to="appUrl + '/profile/my-projects'"
        class="profile-dropdown__link"
      >
        {{ $t('profile.my_projects') }}
        <span class="profile-dropdown__link-number">
          {{ storedUser.active_my_projects_count || 0 }}
        </span>
      </nuxt-link> -->
      <nuxt-link :to="appUrl + '/profile/offer'" class="profile-dropdown__link">
        {{ $t("profile.my_offers") }}
        <span class="profile-dropdown__link-number">
          {{ storedUser.acquisition_count || 0 }}
        </span>
      </nuxt-link>
      <!-- <nuxt-link
        :to="appUrl + '/profile/acquisition'"
        class="profile-dropdown__link"
      >
        {{ $t('profile.my_acquisitions') }}
        <span class="profile-dropdown__link-number">
          {{ storedUser.acquisition_count || 0 }}
        </span>
      </nuxt-link>
      <nuxt-link
        :to="appUrl + '/profile/deposit'"
        class="profile-dropdown__link profile-dropdown__link--total"
      >
        {{ $t('profile.my_deposit') }}
        <span
          v-if="storedUser?.deposit_amount?.formatted_price"
          class="profile-dropdown__total"
        >
          {{ storedUser?.deposit_amount?.formatted_price }}
        </span>
      </nuxt-link> -->
      <nuxt-link
        :to="appUrl + '/profile/account-settings'"
        class="profile-dropdown__link"
      >
        {{ $t("profile.account_settings") }}
      </nuxt-link>
    </div>
    <!-- <div class="profile-dropdown__link-friends">
      <nuxt-link
        :to="appUrl + '/profile/invite-friends'"
        class="profile-dropdown__link"
      >
        <nuxt-icon name="user-plus" filled></nuxt-icon>
        {{ $t('label.link_friends') }}
      </nuxt-link>
    </div> -->
    <div class="profile-dropdown__bottom">
      <a
        href=""
        class="profile-dropdown__link profile-dropdown__link--total"
        :class="{
          'profile-dropdown__link--disabled': signOutPending,
        }"
        @click.prevent="signOut()"
      >
        <span> {{ $t("profile.sign_out") }} </span>
        <spinner
          v-if="signOutPending"
          class="profile-dropdown__link__spinner"
        />
      </a>
      <!-- <start-selling-btn :size="'block'" @click="model = false" /> -->
    </div>
  </div>
</template>
<script lang="ts">
import { BtnMixin, ModelMixin, useModel } from "wue";
import { onClickOutside } from "@vueuse/core";
import type { Ref } from "vue";
import { computed, ref, watch } from "vue";
import type { User } from "@homebourse/api-client";
import StartSellingBtn from "~/modules/home/components/StartSellingBtn.vue";
import Spinner from "~/components/loaders/Spinner.vue";
import { useApiHandler } from "~/composables";
import { useViewTools } from "#imports";
import { useAuthStore, useUserStore } from "~/stores";
import { useRuntimeConfig } from "#app";

export default {
  name: "ProfileBtn",
  components: {
    Spinner,
    StartSellingBtn,
  },
  mixins: [BtnMixin, ModelMixin],
  emits: ["opened", "closed"],
  setup(props, ctx) {
    const userStore = useUserStore();
    const storedUser = computed(() => userStore.user);
    const isLoggedIn = computed(() => !!storedUser.value);
    const modelCtx = useModel<boolean>(props as any, ctx as any, {
      initialValue: false,
    });
    const dropdownTriggerEl = ref<HTMLElement>(null);
    const dropdownEl = ref<HTMLElement>(null);

    const config = useRuntimeConfig();
    const appUrl = config.public.baseUrl;

    const toggleProfileDropdown = () =>
      (modelCtx.model.value = !modelCtx.model.value);

    const { execute: signOut, pending: signOutPending } = useApiHandler(
      async () => {
        const response = await useAuthStore().signOut();
        toggleProfileDropdown();
        return response;
      }
    );

    onClickOutside(
      dropdownEl,
      () => {
        modelCtx.model.value = false;
      },
      { ignore: [dropdownTriggerEl] }
    );

    watch(
      () => modelCtx.model.value,
      (value) => ctx.emit(value ? "opened" : "closed")
    );

    return {
      storedUser: storedUser as Ref<User>,
      isLoggedIn,
      dropdownTriggerEl,
      dropdownEl,
      appUrl,
      signOut,
      signOutPending,
      ...useViewTools(),
      ...modelCtx,
    };
  },
};
</script>
