<template>
  <div class="hamburger">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Hamburger',
  components: {},
})
</script>

<style lang="scss">
.hamburger {
  display: none;
  width: 32px;
  position: relative;
  z-index: 1;

  @include tablet {
    display: block;
  }

  span {
    width: 32px;
    height: 3px;
    background-color: var(--hb-blue1);
    border-radius: 20px;
    display: block;
    margin-left: auto;
    transition: width 0.3s;

    &:nth-of-type(1) {
      width: 22px;
    }

    &:nth-of-type(2) {
      margin: 6px 0;
    }

    &:nth-of-type(3) {
      width: 22px;
    }
  }

  &--open {
    span {
      width: 32px !important;
    }
  }
}
</style>
